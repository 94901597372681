<template>
  <div>
    <!-- 搜索区域 -->
    <el-form>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请选择学生类型">
            <el-select placeholder="请选择学生类型" v-model="search.type">
              <el-option label="新生" :value="0" />
              <el-option label="老生" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入缴款人名称">
            <el-input v-model="search.payername"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入院系名称">
            <el-input v-model="search.collegeName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入专业名称">
            <el-input v-model="search.className"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请选择层次">
            <el-select v-model="search.length" placeholder="请选择层次">
              <el-option
                v-for="item in hierarchicalData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="请输入缴款人身份证号码">
            <el-input v-model="search.payercardno"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="请选择支付时间">
            <el-date-picker
              v-model="paymentTime"
              type="daterange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <el-button type="primary" @click="onRefundSearchBtn">
            搜索
          </el-button>
        </el-col>

        <el-col :span="1">
          <el-button
            style="margin-left: 20px"
            :loading="loading"
            type="success"
            @click="onPayTheFeesexport"
          >
            导出
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格区域 -->
    <el-table
      :data="tableData"
      border
      style="width: 100%; margin-top: 20px"
      stripe
      height="68vh"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column fixed type="selection" width="55" />
      <el-table-column fixed prop="payername" label="缴费人名称" width="100" />
      <el-table-column fixed prop="orderid" label="缴费编号" width="180" />
      <el-table-column fixed prop="amt" label="缴费金额" width="100" />
      <el-table-column prop="paySettingsName" label="缴费名称" width="180" />
      <el-table-column prop="collegeName" label="缴费人院系" width="180" />
      <el-table-column prop="className" label="缴费人专业" width="180" />
      <el-table-column prop="levelName" label="缴费人层次" width="180" />
      <el-table-column prop="trancode" label="交易码" width="180" />
      <el-table-column prop="trantype" label="交易种类" width="180" />
      <el-table-column prop="paytype" label="票据" width="180" />
      <el-table-column prop="xzqh" label="行政区划" width="180" />
      <el-table-column prop="type" label="学生类型" width="180">
        <template v-slot="{ row }">
          {{ row.type ? '老生缴费' : '新生缴费' }}
        </template>
      </el-table-column>
      <el-table-column label="证件号类型" width="180">
        <template v-slot="{ row }">
          {{ row.payercardtype == 10 ? '个人' : '其他' }}
        </template>
      </el-table-column>
      <el-table-column prop="payercardno" label="缴款人证件号码" width="180" />
      <el-table-column prop="paycode" label="缴款码" width="180" />
      <el-table-column prop="filldate" label="发起日期" width="180" />
      <el-table-column prop="payTime" label="缴款时间" width="180" />
      <el-table-column prop="reason" label="退费原因" width="180" />
      <el-table-column
        fixed="right"
        prop="payState"
        label="缴款状态"
        width="100"
      >
        <template v-slot="{ row }">
          {{ row.payState ? '已支付' : '未支付' }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="退费状态" width="100">
        <template v-slot="{ row }">
          {{ row.refundState ? '已退费' : '未退费' }}
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="200" align="center">
        <template #default="{ row }">
          <el-button
            type="primary"
            size="small"
            @click="onEditBtn(row.orderid)"
          >
            明细
          </el-button>

          <el-button
            v-permission="['financePrint']"
            type="primary"
            size="small"
            @click="onPrint(row)"
          >
            打印退费申请
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <detailed-pop-up-window
      v-model="detailVisible"
      :orderid="orderid"
    ></detailed-pop-up-window>

    <el-dialog v-model="refundVisible" title="退费原因" width="30%">
      <el-form ref="ruleFormRef" :model="refundCause" :rules="rules">
        <el-form-item label="退费原因" prop="type">
          <el-select v-model="refundCause.type" placeholder="请选择退费原因">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in refundCauseArr"
              :key="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="refundVisible = false">取消</el-button>
          <el-button type="primary" @click="refoundOk(ruleFormRef)"
            >确认</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
import { listOfItemsAPI, payTheFeesexportAPI } from '@/api/finance'
import { useRouter } from 'vue-router'
import { refundStore } from '@/store/refund'
import detailedPopUpWindow from '../renewal/components/detailedPopUpWindow'
/** 搜索 S */
const search = ref({
  page: 1,
  total: 0,
  limit: 10,
  type: 0, // 新生 0 / 老生 1
  payername: '', // 收款人姓名
  paySettingsName: '', // 缴费名称
  collegeName: '', // 院系名称
  className: '', // 专业名称
  paySetting: '', // 缴费项目
  length: '', // 层次
  payercardno: '', // 身份证
  payTimeStart: '', // 2022-07-07  // 缴费开始
  payTimeEnd: '', // 2022-08-15    // 缴费结束
  upTimeStart: '', // 2022-07-12  // 退费开始
  upTimeEnd: '', // 2022-07-15    // 退费结束
  payState: '', // 0  // 0 未支付 1 已支付
  refundState: '', // 0 // 0 未退费  1 已退费
  start: '' // 入学时间
})

// 退费搜索
const onRefundSearchBtn = () => {
  onPayOrderList()
}

// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()

// 支付时间
const paymentTime = ref([])
watch(
  () => paymentTime,
  (val) => {
    if (!val.value) {
      search.value.payTimeStart = ''
      search.value.payTimeEnd = ''
    } else {
      search.value.payTimeStart = val.value[0]
      search.value.payTimeEnd = val.value[1]
    }
  },
  {
    deep: true
  }
)
// 退费时间
const refundTime = ref([])
watch(
  () => refundTime,
  (val) => {
    search.value.upTimeStart = val.value[0]
    search.value.upTimeEnd = val.value[1]
  },
  {
    deep: true
  }
)

// 导出
const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await payTheFeesexportAPI(search.value)
  window.location.href = `http://lnjsxy.college.yslts.com${data}`
  loading.value = false
}

/** 搜索 E */

/** 表格 S */
// 表格数据
const tableData = ref([])
const onPayOrderList = async () => {
  console.log('search.value', search.value)
  const { data, total } = await listOfItemsAPI(search.value)
  search.value.total = total
  tableData.value = data
}
onPayOrderList()

// 明细
const detailVisible = ref(false)
const orderid = ref('')
const onEditBtn = (id) => {
  detailVisible.value = true
  orderid.value = id
}

// 退费
const refundVisible = ref(false)
const refundCause = ref({
  type: ''
})

const rules = ref({
  type: [{ required: true, message: '必须选择退费原因', trigger: 'blur' }]
})
const refundCauseArr = [
  {
    id: 1,
    name: '退学'
  },
  {
    id: 2,
    name: '第一书记村'
  },
  {
    id: 3,
    name: '建档立卡贫困户'
  },
  {
    id: 4,
    name: '乡村振兴服务队村'
  },
  {
    id: 5,
    name: '专业化管理村'
  },
  {
    id: 6,
    name: '其他原因'
  }
]
// 确认打印
const ruleFormRef = ref(null)
const refoundOk = (formEl) => {
  formEl.validate((valid) => {
    if (!valid) return

    router.push(`/finance/print/${refundCause.value.type}`)
  })
}

// 打印
const router = useRouter()
const store = refundStore()
const onPrint = (item) => {
  refundVisible.value = true
  store.getRefundObj(item)
}

/** 表格 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onPayOrderList()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onPayOrderList()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
